
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import store from '@/store';
// @ is an alias to /src
@Component
export default class NaviEntryPrevNext extends Vue {
  private name = 'navientryprevnext';
  get entryData(): any {
    return this.$store.getters[`single/data`];
  }
  get isPrev(): boolean {
    if (this.entryData && this.entryData.olderPostId) {
      return true;
    } else {
      return false;
    }
  }
  get isNext(): boolean {
    if (this.entryData && this.entryData.newerPostId) {
      return true;
    } else {
      return false;
    }
  }
  get toPrev(): string {
    if (this.entryData && this.entryData.olderPostId) {
      return this.path() + '/' + this.entryData.olderPostId;
    } else {
      return this.$route.fullPath;
    }
  }
  get toNext(): string {
    if (this.entryData && this.entryData.newerPostId) {
      return this.path() + '/' + this.entryData.newerPostId;
    } else {
      return this.$route.fullPath;
    }
  }
  @Emit()
  private path(): string {
    const fullPath = this.$route.fullPath.split('/');
    let result: string = '';
    const regex = /\/[0-9]+$/;
    if (regex.test(this.$route.fullPath)) {
      result = this.$route.fullPath.slice(0, this.$route.fullPath.lastIndexOf('/'));
    } else {
      result = this.$route.fullPath;
    }
    return result;
  }
}
