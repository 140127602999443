import { Component, Vue, Emit, Watch } from 'vue-property-decorator';
import store from '@/store';
import BoxEntriesHeaderDate from '@/components/box/BoxEntriesHeaderDate/BoxEntriesHeaderDate.vue';
import BoxEntriesHeaderTitle from '@/components/box/BoxEntriesHeaderTitle/BoxEntriesHeaderTitle.vue';
import BoxEntriesBody from '@/components/box/BoxEntriesBody/BoxEntriesBody.vue';
import NaviEntryPrevNext from '@/components/navi/NaviEntryPrevNext.vue';
import NaviEntryPlans from '@/components/navi/NaviEntryPlans.vue';
import $ from 'jquery';
import VueRouter from 'vue-router';
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
  'beforeRouteLeave',
]);

@Component({
  components: {
    BoxEntriesHeaderDate,
    BoxEntriesHeaderTitle,
    BoxEntriesBody,
    NaviEntryPrevNext,
    NaviEntryPlans,
  },
})
export default class SinglepageContainer extends Vue {
  get loadedPost(): boolean {
    return this.$store.getters[`single/loaded`];
  }
  get showPlans(): boolean {
    if (this.loadedPost && this.entryData.hotelDetail.length > 0) {
      return true;
    } else {
      return false;
    }
  }
  get entryData(): PostData {
    return this.$store.getters[`single/data`];
  }
  private gwcouponData: any = {
    'sendai': [
      {
        value: 5,
        text: '三井ガーデンホテル仙台',
        url: 'https://secure.reservation.jp/gardenhotels/stay_pc/rsv/cc_pln_dtl_redirect.aspx?smp_id=652&hi_id=5&cp_id=mfg2021gw&lang=ja-JP&cp=2&is_cal=True',
      },
    ],
    'chiba': [
      {
        value: 7,
        text: '三井ガーデンホテル千葉',
        url: 'https://secure.reservation.jp/gardenhotels/stay_pc/rsv/cc_pln_dtl_redirect.aspx?smp_id=490&hi_id=7&cp_id=mfg2021gw&lang=ja-JP&cp=2&is_cal=True',
      },
      {
        value: 20,
        text: '三井ガーデンホテル柏の葉',
        url: 'https://secure.reservation.jp/gardenhotels/stay_pc/rsv/cc_pln_dtl_redirect.aspx?smp_id=270&hi_id=20&cp_id=mfg2021gw&lang=ja-JP&cp=2&is_cal=True',
      },
      {
        value: 6,
        text: '三井ガーデンホテルプラナ東京ベイ',
        url: 'https://secure.reservation.jp/gardenhotels/stay_pc/rsv/cc_pln_dtl_redirect.aspx?smp_id=984&hi_id=6&cp_id=mfg2021gw&lang=ja-JP&cp=2&is_cal=True',
      },
    ],
    'kanazawa': [
      {
        value: 30,
        text: '三井ガーデンホテル金沢',
        url: 'https://secure.reservation.jp/gardenhotels/stay_pc/rsv/cc_pln_dtl_redirect.aspx?smp_id=112&hi_id=30&cp_id=mfg2021gw&lang=ja-JP&cp=2&is_cal=True',
      },
    ],
    'kyoto': [
      {
        value: 10,
        text: '三井ガーデンホテル京都三条',
        url: 'https://secure.reservation.jp/gardenhotels/stay_pc/rsv/cc_pln_dtl_redirect.aspx?smp_id=694&hi_id=10&cp_id=mfg2021gw&lang=ja-JP&cp=2&is_cal=True',
      },
      {
        value: 11,
        text: '三井ガーデンホテル京都四条',
        url: 'https://secure.reservation.jp/gardenhotels/stay_pc/rsv/cc_pln_dtl_redirect.aspx?smp_id=694&hi_id=11&cp_id=mfg2021gw&lang=ja-JP&cp=2&is_cal=True',
      },
      {
        value: 32,
        text: '三井ガーデンホテル京都駅前',
        url: 'https://secure.reservation.jp/gardenhotels/stay_pc/rsv/cc_pln_dtl_redirect.aspx?smp_id=134&hi_id=31&cp_id=mfg2021gw&lang=ja-JP&cp=2&is_cal=True',
      },
      {
        value: 19,
        text: '三井ガーデンホテル京都新町別邸',
        url: 'https://secure.reservation.jp/gardenhotels/stay_pc/rsv/cc_pln_dtl_redirect.aspx?smp_id=322&hi_id=19&cp_id=mfg2021gw&lang=ja-JP&cp=2&is_cal=True',
      },
      {
        value: 25,
        text: 'ホテル ザ セレスティン京都祇園',
        url: 'https://secure.reservation.jp/gardenhotels/stay_pc/rsv/cc_pln_dtl_redirect.aspx?smp_id=188&hi_id=25&cp_id=mfg2021gw&lang=ja-JP&cp=2&is_cal=True',
      },
    ],
    'osaka': [
      {
        value: 18,
        text: '三井ガーデンホテル大阪プレミア',
        url: 'https://secure.reservation.jp/gardenhotels/stay_pc/rsv/cc_pln_dtl_redirect.aspx?smp_id=300&hi_id=18&cp_id=mfg2021gw&lang=ja-JP&cp=2&is_cal=True',
      },
      {
        value: 12,
        text: '三井ガーデンホテル大阪淀屋橋',
        url: 'https://secure.reservation.jp/gardenhotels/stay_pc/rsv/cc_pln_dtl_redirect.aspx?smp_id=384&hi_id=12&cp_id=mfg2021gw&lang=ja-JP&cp=2&is_cal=True',
      },
    ],
    'hiroshima-okayama': [
      {
        value: 14,
        text: '三井ガーデンホテル広島',
        url: 'https://secure.reservation.jp/gardenhotels/stay_pc/rsv/cc_pln_dtl_redirect.aspx?smp_id=1058&hi_id=14&cp_id=mfg2021gw&lang=ja-JP&cp=2&is_cal=True',
      },
      {
        value: 13,
        text: '三井ガーデンホテル岡山',
        url: 'https://secure.reservation.jp/gardenhotels/stay_pc/rsv/cc_pln_dtl_redirect.aspx?smp_id=452&hi_id=13&cp_id=mfg2021gw&lang=ja-JP&cp=2&is_cal=True',
      },
    ],
    'fukuoka-kumamoto': [
      {
        value: 31,
        text: '三井ガーデンホテル福岡祇園',
        url: 'https://secure.reservation.jp/gardenhotels/stay_pc/rsv/cc_pln_dtl_redirect.aspx?smp_id=125&hi_id=32&cp_id=mfg2021gw&lang=ja-JP&cp=2&is_cal=True',
      },
      {
        value: 15,
        text: '三井ガーデンホテル熊本',
        url: 'https://secure.reservation.jp/gardenhotels/stay_pc/rsv/cc_pln_dtl_redirect.aspx?smp_id=331&hi_id=15&cp_id=mfg2021gw&lang=ja-JP&cp=2&is_cal=True',
      },
    ],
    'tokyo': [
      {
        value: 24,
        text: 'ホテル ザ セレスティン銀座',
        url: 'https://secure.reservation.jp/gardenhotels/stay_pc/rsv/cc_pln_dtl_redirect.aspx?smp_id=275&hi_id=24&cp_id=mfg2021gw&lang=ja-JP&cp=2&is_cal=True',
      },
      {
        value: 26,
        text: 'ホテル ザ セレスティン東京芝',
        url: 'https://secure.reservation.jp/gardenhotels/stay_pc/rsv/cc_pln_dtl_redirect.aspx?smp_id=427&hi_id=26&cp_id=mfg2021gw&lang=ja-JP&cp=2&is_cal=True',
      },
      {
        value: 1,
        text: '三井ガーデンホテル銀座プレミア',
        url: 'https://secure.reservation.jp/gardenhotels/stay_pc/rsv/cc_pln_dtl_redirect.aspx?smp_id=807&hi_id=1&cp_id=mfg2021gw&lang=ja-JP&cp=2&is_cal=True',
      },
      {
        value: 21,
        text: 'ミレニアム 三井ガーデンホテル 東京',
        url: 'https://secure.reservation.jp/gardenhotels/stay_pc/rsv/cc_pln_dtl_redirect.aspx?smp_id=494&hi_id=21&cp_id=mfg2021gw&lang=ja-JP&cp=2&is_cal=True',
      },
      {
        value: 33,
        text: '三井ガーデンホテル銀座五丁目',
        url: 'https://secure.reservation.jp/gardenhotels/stay_pc/rsv/cc_pln_dtl_redirect.aspx?smp_id=221&hi_id=33&cp_id=mfg2021gw&lang=ja-JP&cp=2&is_cal=True',
      },
      {
        value: 2,
        text: '三井ガーデンホテル汐留イタリア街',
        url: 'https://secure.reservation.jp/gardenhotels/stay_pc/rsv/cc_pln_dtl_redirect.aspx?smp_id=952&hi_id=2&cp_id=mfg2021gw&lang=ja-JP&cp=2&is_cal=True',
      },
      {
        value: 17,
        text: '三井ガーデンホテル上野',
        url: 'https://secure.reservation.jp/gardenhotels/stay_pc/rsv/cc_pln_dtl_redirect.aspx?smp_id=815&hi_id=17&cp_id=mfg2021gw&lang=ja-JP&cp=2&is_cal=True',
      },
      {
        value: 34,
        text: '三井ガーデンホテル神宮外苑の杜プレミア',
        url: 'https://secure.reservation.jp/gardenhotels/stay_pc/rsv/cc_pln_dtl_redirect.aspx?smp_id=245&hi_id=34&cp_id=mfg2021gw&lang=ja-JP&cp=2&is_cal=True',
      },
      {
        value: 35,
        text: '三井ガーデンホテル六本木プレミア',
        url: 'https://secure.reservation.jp/gardenhotels/stay_pc/rsv/cc_pln_dtl_redirect.aspx?smp_id=179&hi_id=35&cp_id=mfg2021gw&lang=ja-JP&cp=2&is_cal=True',
      },
      {
        value: 37,
        text: 'sequence SUIDOBASHI',
        url: 'https://secure.reservation.jp/gardenhotels/stay_pc/rsv/cc_pln_dtl_redirect.aspx?smp_id=114&hi_id=42&cp_id=mfg2021gw&lang=ja-JP&cp=2&is_cal=True',
      },
    ],
  };

  @Emit()
  public campaignAgreeBoxInit() {
    const $campaignAgreeBox: any = $('#campaign_agree_box');
    if ($campaignAgreeBox) {
      this.hasAgreeBox();
    }
  }
  @Emit()
  public gwcouponInit() {
    const self = this;
    if (typeof $('#area-select') !== 'undefined') {
      const areaVal = $('#area-select option:selected').val();
      if (typeof areaVal !== 'undefined') {
        this.onChangeSelect(String(areaVal));
      }
      $('#area-select').on('change', function() {
        const selectVal = $('option:selected', this).val();
        self.onChangeSelect(String(selectVal));
      });
    }
    $('.gwcoupon_box .btSubmit').on('click', (event) => {
      event.preventDefault();
      const selectHotelVal = $('#hotel-select').val();
      const selectAreaVal = $('#area-select').val();
      const data = this.gwcouponData[String(selectAreaVal)].filter((item: any, index: number) => {
        if (item.value === Number(selectHotelVal)) {
          return true;
        }
      });
      window.open(data[0].url);
    });
  }
  @Emit()
  private onChangeSelect(area: string) {
    const $hotelSelect = $('#hotel-select');
    const data: any = this.gwcouponData[area];
    if (typeof data !== 'undefined' && typeof $hotelSelect !== 'undefined') {
      $('#hotel-select > option').remove();
      for (const item of data) {
        $('#hotel-select').append($('<option>').html(item.text).val(item.value));
      }
    }
  }
  private updated() {
    // this.loaded = true;
    const $campaignAgreeBox: any = $('#campaign_agree_box');
    if ($campaignAgreeBox) {
      this.hasAgreeBox();
    }
  }
  @Emit()
  private hasAgreeBox() {
    const $input: any = $('.single_campaign_agree_box_checkbox input');
    const change = () => {
      if ($input.prop('checked')) {
        $('.single_campaign_agree_box_button--inactivein').hide();
        $('.single_campaign_agree_box_button form').show();
      } else {
        $('.single_campaign_agree_box_button--inactivein').show();
        $('.single_campaign_agree_box_button form').hide();
      }
    };
    $(document).on('change', '.single_campaign_agree_box_checkbox input', function(e) {
      e.preventDefault();
      change();
    });
    $('.single_campaign_agree_box_button form').submit(function() {
      if (!$('.single_campaign_agree_box_checkbox input').prop('checked')) {
        return false;
      }
    });
    change();
  }
}
