
import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
import GaSendSite from '@/directives/GaSendSite';

// @ is an alias to /src
@Component({
  directives: {
    gasendsite: GaSendSite,
  },
})
export default class NaviEntryPlans extends Vue {
  private name = 'NaviEntryPlans';
  @Prop({default: false})
  private vertical!: boolean;
  @Prop()
  private data!: HotelDetail[];
  get planDatas(): any {
    const plans: any[] = [];
    for (const hotel of this.data) {
      for (const plan of hotel.stayingPlans) {
        plans.push({
          name: hotel.name,
          htl_id: hotel.htl_id,
          plan,
        });
      }
    }
    return plans;
  }
  @Emit()
  private buttonLabel(hotelData: any): string {
    if (hotelData.plan.buttonLabelLong.length > 0) {
      return hotelData.plan.buttonLabelLong;
    } else {
      return `「${hotelData.name}」を特別優待料金で予約する`;
    }
  }
}
