
import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
// @ is an alias to /src

@Component
export default class BoxEntriesBody extends Vue {
  private name = 'boxentriesbody';
  @Prop()
  private entryData!: {
    id?: string,
    categoryName?: string,
    categoryLabelColor?: string,
    categoryTextColor?: string,
    date: string,
    title: string,
    hotelName?: string,
    content: HTMLElement,
    newIcon: boolean,
    hotelDetail?: any[],
  };
}
