
import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
import store from '@/store';

@Component
export default class BoxEntriesHeaderName extends Vue {
  private name = 'BoxEntriesHeaderName';
  @Prop()
  private title!: string;
  get lang(): string {
    return store.getters['auth/lang'];
  }
  get isGroup(): boolean {
    return this.$store.getters['auth/group'];
  }
}
