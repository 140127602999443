
import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
// @ is an alias to /src

@Component
export default class BoxEntriesHeaderDate extends Vue {
  private name = 'BoxEntriesHeaderDate';
  @Prop()
  private entryData!: {
    date: string,
    newIcon: boolean,
    categoryName?: string,
    categoryLabelColor?: string,
    categoryTextColor?: string,
  };
}
